.z-max {
  z-index: 999999 !important;
}

::-webkit-scrollbar {
  display: none;
}

.list-row p {
  padding-left: 8px;
}

.list-row:nth-child(odd) p {
  background-color: #cccccc;
}

.list-row:nth-child(even) p {
  background-color: #99cccc;
}

.event-button {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(2555, 255, 255, 0.8);
}

.event-button span {
  vertical-align: super;
}

.race-row-0 {
  background-color: #cccccc;
}

.race-row-1 {
  background-color: #99cccc;
}

.race-row-2 {
  background-color: #99cccc;
}

.race-row-3 {
  background-color: #cccccc;
}

.race-name p {
  font-size: 20px;
}

.race-detail p {
  margin: 0;
  font-size: 1em;
  color: black;
}

.rate-label {
  font-size: 20px;
  margin-right: 5px;
}

.ant-card-body {
  padding: 8px;
}

.chosen-card {}

.un-chosen-card img {
  opacity: 0.3;
}

.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.my-drawer .am-drawer-sidebar {
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.my-drawer .am-drawer-sidebar .am-list {
  width: 300px;
  padding: 0;
}

/*race time line start */
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute !important;
  top: 0px !important;
  width: 100px !important;
  text-align: right !important;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  top: 6px !important;
  left: 110px !important;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  top: 0px !important;
  left: 110px !important;
  width: calc(100% - 120px) !important;
  text-align: left !important;
  margin: 0 0 0 8px !important;
}

.ant-timeline-item-content p {
  margin-bottom: 4px !important;
}

.ant-timeline-item {
  position: relative !important;
  margin: 0 !important;
  padding-bottom: 6px !important;
  font-size: 14px !important;
  list-style: none !important;
}

/*race time line end */

.seed-container {
  margin: 24px 24px 0px;
}

.seed-container>* {
  margin-bottom: 16px;
}

.seed-container>*:last-child {
  margin-bottom: 0;
}

.seed-container .card {
  padding: 24px 24px 0;
}

.ant-table-wrapper {
  max-width: unset !important;
}

.ant-modal-body {
  padding: 12px !important;
}

.ant-divider-horizontal {
  margin: 6px 0 !important;
}


